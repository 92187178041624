import React, { useEffect } from 'react'
import Head from 'next/head'
import Layout from '@Layouts/Default/index'
import { useSessionId } from '@shared/hooks/useSessionId'
import { useLoading } from '@shared/hooks/useLoading'
import { useCheckout } from '@shared/hooks/useCheckout'

import { useCart } from '@shared/hooks/useCart'

import { useRouter } from 'next/router'

import { useCartIsReady } from '@shared/hooks/useCartIsReady'
import CONSTANTS from '@Constants/index'
import { useFidelity } from '@shared/hooks/useFidelity'

const Home = () => {
  const { globalLoading, onSetGlobalLoading } = useCheckout()
  const router = useRouter()
  const { getCart } = useCart()
  const { onSetSessionId } = useSessionId()
  const { onSetIsLoading } = useLoading()
  const { onSetCartIsReady } = useCartIsReady()
  const { onSetIsFidelity, onSetSameCpfFidelity } = useFidelity()
  const redirect = async (sessionId: string) => {
    onSetGlobalLoading(true)
    onSetIsLoading(true)
    onSetSessionId(sessionId)
  }

  useEffect(() => {
    if (globalLoading) {
      sessionStorage.removeItem(CONSTANTS.SESSIONSTORAGE.SESSION_DATA)
      onSetIsLoading(true)
      getCart()
    }
    onSetCartIsReady(true)
  }, [globalLoading])

  useEffect(() => {
    const { sessionId } = router.query
    onSetIsFidelity(false)
    onSetSameCpfFidelity(false)

    if (sessionId) {
      sessionStorage.removeItem(CONSTANTS.SESSIONSTORAGE.SESSION_DATA)
      onSetSessionId(sessionId as string)
      redirect(sessionId as string)
    }
  }, [])
  return (
    <Layout sessionIdUrl={router.query.sessionId as string}>
      <Head>
        <title>Carrinho vazio - Ingresso.com</title>
        <meta name="description" content="Carinho vazio" />
      </Head>
    </Layout>
  )
}
export default Home
