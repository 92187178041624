import { GetServerSideProps } from 'next'

import Home from '@Templates/Home/Home'

export const getServerSideProps: GetServerSideProps = async () => {
  return {
    props: {}
  }
}

export default Home
